// ProductDetails.js
import React, { useState } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductDetails = ({ product }) => {
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);

    let imageUrls = [];
    if (product['Local_Images']) {
        try {
            imageUrls = JSON.parse(product['Local_Images'].replace(/'/g, '"'));
        } catch (error) {
            console.error('Error parsing image URLs:', error);
        }
    }

    const handleRequestPrice = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        const formData = new FormData(e.target);
        const payload = {
            customerName: formData.get('c_name'),
            customerEmail: formData.get('c_email'),
            productName: formData.get('p_title'),
            productUrl: formData.get('p_url'),
        };
        try {
            const response = await fetch('/api/send-email', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(payload),
            });
            const data = await response.json();
            setIsSubmitting(false);
            if (data.success) {
                alert('Request sent successfully!');
            } else {
                alert('Failed to send request.');
            }
        } catch (error) {
            console.error('Error sending email:', error);
            setIsSubmitting(false);
            alert('Error sending request.');
        }
    };

    // Slider settings
    const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 450,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    return (
        <>
            <h4 className="text-sm md:text-lg font-bold bg-clip-text text-transparent bg-gradient-to-r from-gray-400 via-gray-300 to-gray-500 font-righteous">{product.Brand}</h4>
            <h3 className="text-lg md:text-xl font-bold mb-2">{product.Title}</h3>
            <img src={`../${imageUrls[0]}`} alt={`Product image 1`} className="w-full sm:w-[50vw] mx-auto rounded my-4" />
            <div dangerouslySetInnerHTML={{ __html: product['Long_Description'] }} className="my-4"></div>
            <form onSubmit={handleRequestPrice} className="space-y-4">
                <input
                    name="c_name"
                    type="text"
                    placeholder="Full Name"
                    className="border p-2 rounded w-full"
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                />
                <input
                    name="c_email"
                    type="email"
                    placeholder="Enter your email"
                    className="border p-2 rounded w-full"
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    required
                />
                <input name="p_title" type="hidden" value={product.Title} />
                <input name="p_url" type="hidden" value={`${window.location.origin}/product/${product.id}`} />
                <button
                    type="submit"
                    disabled={isSubmitting}
                    className="bg-black text-white py-3 px-6 rounded hover:bg-opacity-90 transition duration-300 mt-4 w-48 mx-auto font-bold focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                >
                    {isSubmitting ? 'Sending...' : 'Request Price'}
                </button>
            </form>
            {/* Slider for all images */}
            {/*
            {imageUrls.length > 1 ? (
                <Slider {...sliderSettings} className="mx-auto max-w-[90vw]">
                {imageUrls.slice(0, -1).map((url, index) => (
                    <div key={index}>
                    <img src={url} alt={`Product image ${index + 1}`} className="w-full mx-auto rounded" />
                    </div>
                ))}
                </Slider>
            ) : (
                // If there's only one image, display it without the Slider
                imageUrls.length === 1 && (
                <img src={imageUrls[0]} alt={`Product image 1`} className="w-full md:w-[90vw] mx-auto rounded" />
                )
            )}
            */}
        </>
    );
};

export default ProductDetails;
