// ProductList.js
import React from 'react';
import ProductItem from './ProductItem';
import InfiniteScroll from 'react-infinite-scroll-component';

const ProductList = ({ products, fetchMoreProducts, hasMore }) => {
  return (
    <InfiniteScroll
      dataLength={products.length}
      next={fetchMoreProducts}
      hasMore={hasMore}
      className="p-4 pl-5"
      scrollThreshold={0.9}
      loader={
        hasMore ? (
          <div className="flex h-32">
            <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-black mx-auto mt-8"></div>
          </div>
        ) : null
      }
      endMessage={
        <p className="text-center text-gray-600 py-4">
          {products.length > 0 ? 'No more products to load' : 'No products found'}
        </p>
      }
    >
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2 md:gap-4">
        {products.map((product) => (
          <ProductItem key={product.id} product={product} />
        ))}
      </div>
    </InfiniteScroll>
  );
};

export default ProductList;
