import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Routes, Route } from 'react-router-dom';
import './App.css';
import ProductList from './ProductList';
import Navbar from './Navbar';
import ProductPage from './ProductPage';
import BackToTop from './BackToTop';
// import Login from './Login';

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const category = query.get('category') || 'All';
  const [products, setProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [selectedCategory, setSelectedCategory] = useState(category);
  const [prevCategory, setPrevCategory] = useState(null);
  const step = 200; // Number of products to load each time

  const [categories, setCategories] = useState(['All']); // Initialize with just 'All'

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await fetch('/api/categories');
        const data = await response.json();
        // Filter out any null values and add 'All' category
        const validCategories = data.filter(category => category != null);
        setCategories(['All', ...validCategories]);
      } catch (error) {
        console.error('Error fetching categories:', error);
      }
    };
  
    fetchCategories();
  }, []);

  const fetchProducts = async (pageNum = page, category = selectedCategory) => {
    try {
      const response = await fetch(`/api/products?page=${pageNum}&size=${step}&category=${category}`);
      const result = await response.json();
      
      if (pageNum === 1) {
        setProducts(result.data);
      } else {
        setProducts(prev => [...prev, ...result.data]);
      }
      setHasMore(result.hasMore);
      setPage(pageNum);
    } catch (error) {
      console.error('Error fetching products:', error);
      setHasMore(false);
    }
  };

  const loadMoreProducts = () => {
    if (hasMore) {
      fetchProducts(page + 1);
    }
  };

  useEffect(() => {
    setPage(1);
    setHasMore(true);
    setPrevCategory(selectedCategory);
    fetchProducts(1, selectedCategory);
  }, [selectedCategory]);

  return (
    <div className="App pt-16">
      <Navbar
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        categories={categories}
      />
      <Routes>
        <Route path="/" element={
          <ProductList 
            products={products} 
            fetchMoreProducts={loadMoreProducts} 
            hasMore={hasMore} 
          />
        } />
        <Route path="/product/:productId" element={<ProductPage />} />
        {/* <Route path="/login" element={<Login />} /> */}
      </Routes>
      <BackToTop />
    </div>
  );
}

export default App;
